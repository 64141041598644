<template>
  <b-card title="المحتوى">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='course-content'"
        @click="$router.push('/course-content')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/course-content"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>محتوى الدورة </span>
          </a>
        </template>

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='category-content'"
        @click="$router.push('/category-content')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/category-content"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span> محتوى الفئة </span>
          </a>
        </template>

        <!-- <CategoryContent></CategoryContent>/ -->
      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='category-page-content'"
        @click="$router.push('/category-page-content')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/category-page-content"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>محتوى الفئة </span>
          </a>
        </template>

        <!-- <CategoryPageContent></CategoryPageContent> -->
      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='city-content'"
        @click="$router.push('/city-content')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/city-content"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span> محتوى المدينة </span>
          </a>
        </template>

        <!-- <CityContent></CityContent> -->
      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='category-in-city-content'"
        @click="$router.push('/category-in-city-content')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/category-in-city-content"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span> الفئة في المدينة </span>
          </a>
        </template>

        <!-- <CategoryinCityContent></CategoryinCityContent> -->
      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <CourseContent v-if="route.name === 'course-content' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import CourseContent from './CourseContent.vue'
import CategoryContent from './CategoryContent.vue'
import CategoryPageContent from './CategoryPageContent.vue'
import CityContent from './CityContent.vue'
import CategoryinCityContent from './CategoryinCityContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    CourseContent,
    CategoryContent,
    CategoryPageContent,
    CityContent,
    CategoryinCityContent,
  },
  data() {
    return {
      role: {},
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  created() {
    this.role = JSON.parse(localStorage.getItem('userData'))
  },
}
</script>
